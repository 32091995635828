import { Web3Provider } from "@ethersproject/providers";
import { parseEther } from "@ethersproject/units";
import { NftSwapV3, Order, SignedOrder } from "@traderxyz/nft-swap-sdk";
import React, { useEffect, useState } from "react";
import { useAppContext } from "../hooks/useAppContext";
import { useNftSwapSdk } from "../hooks/useNftSwapSdk";
import { useSubmitOrderSignature } from "../hooks/useSubmitOrderSignature";
import { useUpdateTxHash } from "../hooks/useUpdateTxHash";
import useWeb3Modal from "../hooks/useWeb3Modal";
import { Button } from "./Button.react";

export function StartSwapButton({
  enabled,
  isSelf,
}: {
  enabled: boolean;
  isSelf: boolean;
}): React.ReactElement {
  const { state } = useAppContext();
  const { provider: _provider } = useWeb3Modal();
  const provider = _provider as unknown as Web3Provider;
  const [signature, setSignature] = useState<string>("");
  const { submitOrderSignature } = useSubmitOrderSignature({ signature });
  const { nftSwapSdk }: { nftSwapSdk?: NftSwapV3 } = useNftSwapSdk();
  const [txHash, setTxHash] = useState<string>("");
  const { updateTxHash } = useUpdateTxHash({
    tradeId: state.trade.tradeID,
    txHash,
  });

  if (
    state.trade.order.makerAddress == state.walletID &&
    state.trade.order.signature
  ) {
    enabled = false;
  } else if (
    state.trade.order.takerAddress == state.walletID &&
    !state.trade.order.signature
  ) {
    enabled = false;
  }

  // Sign order
  useEffect(() => {
    if (signature) {
      submitOrderSignature();
      return;
    }
  }, [signature, submitOrderSignature]);

  // Submit Transaction Hash
  useEffect(() => {
    if (!txHash) {
      return;
    }
    updateTxHash();
  }, [txHash, updateTxHash]);

  async function sign() {
    if (!provider) {
      return;
    }
    if (!state.trade.order) {
      return;
    }
    if (state.trade.order.signature) {
      return;
    }
    if (state.trade.order.makerAddress != state.walletID) {
      return;
    }

    const order: Order = state.trade.order;
    const signedOrder: SignedOrder | undefined = await nftSwapSdk?.signOrder(
      order,
      state.walletID
    );
    setSignature(signedOrder?.signature || "");
  }

  async function fillSignedOrder() {
    if (!nftSwapSdk) {
      return;
    }
    if (!state.trade.order.signature) {
      return;
    }

    const signedOrder: SignedOrder = {
      ...state.trade.order,
      signature: state.trade.order.signature,
    };

    const gasPrice = (await provider.getGasPrice()).mul(2);

    const fillTx = await nftSwapSdk.fillSignedOrder(signedOrder, undefined, {
      gasPrice,
      gasLimit: "500000",
      value: parseEther("0.001"),
    });
    setTxHash(fillTx.hash);
  }

  return (
    <Button
      gradient={isSelf}
      gradientReverse={!isSelf}
      large
      title={"Start Swap"}
      onClick={() => {
        if (state.trade.order.makerAddress == state.walletID) {
          sign();
        } else if (state.trade.order.takerAddress == state.walletID) {
          fillSignedOrder();
        } else {
          console.error("Unknown address attempting to sign");
        }
      }}
      borderRadius={92}
      disabled={!enabled}
      type='button'
      marginBottom={15}
    >
      Start Swap
    </Button>
  );
}
