import { Box, Flex } from "@chakra-ui/react";
import React from "react";

const Header = () => {
  const [show, setShow] = React.useState(false);
  const toggleMenu = () => setShow(!show);

  return (
    <Flex
      as='nav'
      align='center'
      justify='space-between'
      wrap='wrap'
      w='100%'
      h='81px'
      mb={8}
      p={8}
      bg={["primary.500", "primary.500", "transparent", "transparent"]}
      color={["white", "white", "primary.700", "primary.700"]}
      borderBottom='1px'
      borderBottomColor='gray.200'
      boxShadow='sm'
    >
      <Flex align='center'>
        <svg
          width='144'
          height='26'
          viewBox='0 0 144 26'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M69.456 4.148H73.684L75.756 10.504L77.828 4.148H82.084L78.696 13.388L80.712 18.68L85.696 4.12H90.708L82.952 24H78.976L75.756 16.216L72.564 24H68.56L60.832 4.12H65.816L70.828 18.68L72.788 13.388L69.456 4.148ZM95.8066 4.12H100.763L107.595 24H102.891L101.351 19.548H95.1626L93.6506 24H88.9466L95.8066 4.12ZM100.595 16.384L98.2706 8.74L95.8906 16.384H100.595ZM109.552 24V4.12H118.512C119.446 4.12 120.304 4.316 121.088 4.708C121.891 5.08133 122.582 5.59467 123.16 6.248C123.739 6.88267 124.187 7.60133 124.504 8.404C124.84 9.20667 125.008 10.0187 125.008 10.84C125.008 11.6613 124.878 12.4453 124.616 13.192C124.355 13.92 123.982 14.5827 123.496 15.18C123.011 15.7587 122.432 16.2347 121.76 16.608L126.128 24H121.06L117.252 17.588H114.144V24H109.552ZM114.144 13.584H118.344C118.718 13.584 119.044 13.472 119.324 13.248C119.623 13.0053 119.866 12.6787 120.052 12.268C120.239 11.8573 120.332 11.3813 120.332 10.84C120.332 10.2987 120.22 9.832 119.996 9.44C119.791 9.02933 119.53 8.712 119.212 8.488C118.895 8.264 118.559 8.152 118.204 8.152H114.144V13.584ZM128.31 24V4.12H136.794C137.746 4.12 138.614 4.316 139.398 4.708C140.201 5.08133 140.892 5.59467 141.47 6.248C142.049 6.88267 142.497 7.60133 142.814 8.404C143.132 9.20667 143.29 10.028 143.29 10.868C143.29 12.0067 143.02 13.0893 142.478 14.116C141.956 15.1427 141.218 15.9827 140.266 16.636C139.333 17.2707 138.222 17.588 136.934 17.588H132.902V24H128.31ZM132.902 13.584H136.654C137.009 13.584 137.336 13.4813 137.634 13.276C137.933 13.0707 138.176 12.7627 138.362 12.352C138.549 11.9413 138.642 11.4373 138.642 10.84C138.642 10.224 138.53 9.72 138.306 9.328C138.101 8.91733 137.83 8.61867 137.494 8.432C137.177 8.24533 136.841 8.152 136.486 8.152H132.902V13.584Z'
            fill='#333333'
          />
          <path
            d='M36.5981 24.5C35.4434 26.5 32.5566 26.5 31.4019 24.5L20.1436 5C18.9889 3 20.4323 0.499997 22.7417 0.499997L45.2583 0.499999C47.5677 0.5 49.0111 3 47.8564 5L36.5981 24.5Z'
            fill='url(#paint0_linear_120_14682)'
          />
          <path
            d='M17.5981 24.5C16.4434 26.5 13.5566 26.5 12.4019 24.5L1.1436 5C-0.0111032 3 1.43227 0.499997 3.74167 0.499997L26.2583 0.499999C28.5677 0.5 30.0111 3 28.8564 5L17.5981 24.5Z'
            fill='url(#paint1_linear_120_14682)'
          />
          <defs>
            <linearGradient
              id='paint0_linear_120_14682'
              x1='13.3774'
              y1='98.6667'
              x2='67.6142'
              y2='92.4078'
              gradientUnits='userSpaceOnUse'
            >
              <stop stop-color='#00FFD8' />
              <stop offset='1' stop-color='#1857F2' />
            </linearGradient>
            <linearGradient
              id='paint1_linear_120_14682'
              x1='36.9835'
              y1='82.2744'
              x2='-36.8792'
              y2='69.3482'
              gradientUnits='userSpaceOnUse'
            >
              <stop stop-color='#00FFD8' />
              <stop offset='1' stop-color='#7000FF' />
            </linearGradient>
          </defs>
        </svg>
      </Flex>

      <Box
        display={{ base: show ? "block" : "none", md: "block" }}
        flexBasis={{ base: "100%", md: "auto" }}
      >
        <Flex
          align='center'
          justify={["center", "space-between", "flex-end", "flex-end"]}
          direction={["column", "row", "row", "row"]}
          pt={[4, 4, 0, 0]}
        ></Flex>
      </Box>
    </Flex>
  );
};

export default Header;
