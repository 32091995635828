import { Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import CreateTradeButton from "../components/CreateTradeButton.react";
import Header from "../components/Header.react";
import JoinTradeButton from "../components/JoinTradeButton.react";

export default function About() {
  return (
    <Flex direction='column' align='center' m='0 auto'>
      <Header />
      <Flex direction='column' align='center' m='0 auto' w='796px'>
        <Flex h='140px' alignItems='center' mt='110px'>
          <Heading as='h1' color='#333' size='3xl' textAlign={"center"}>
            Create your private and secure trade room
          </Heading>
        </Flex>
        <Flex h='33px' mt='10px'>
          <Text color='#828282' fontSize='3xl' textAlign={"center"}>
            Trade NFTs and tokens instantly and securely.
          </Text>
        </Flex>
        <Flex h='55px' mt='60px'>
          <CreateTradeButton></CreateTradeButton>
        </Flex>
        <Flex h='19px' mt='30px'>
          <Text color='#828282' fontSize='md' textAlign={"center"}>
            Or join an existing trade room
          </Text>
        </Flex>
        <Flex h='55px' mt='16px'>
          <JoinTradeButton></JoinTradeButton>
        </Flex>
      </Flex>
    </Flex>
  );
}
