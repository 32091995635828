import { Tooltip } from "@chakra-ui/react";
import React from "react";
import styled, { css } from "styled-components";
import { ConfirmAssetsButton } from "../components/ConfirmAssetsButton.react";
import { Heading1 } from "../components/Heading.react";
import { Pill } from "../components/Pill.react";
import { Row } from "../components/Row.react";
import { SelectNftsButton } from "../components/SelectNftsButton.react";
import { StartSwapButton } from "../components/StartSwapButton.react";
import { SwapCardScrollableContent } from "../components/SwapCardScrollableContent.react";
import { SwapItem } from "../components/SwapItem.react";
import { WalletButton } from "../components/WalletButton";
import {
  selectWalletName,
  TxHashStatus,
  useAppContext,
} from "../hooks/useAppContext";
import useWeb3Modal from "../hooks/useWeb3Modal";
import { Asset, Participant } from "../interfaces";

export declare type Mode = "edit" | "locked" | "read-only" | "locked-read-only";

interface Props {
  readonly mode: Mode;
  readonly swappingAnimation?: boolean;
}

const SwapCardFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 0px 30px;
`;

const InnerFooterText = styled.div`
  display: flex;
  align-self: center;
  position: absolute;
  bottom: 4px;
  color: #ffffff;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 700;
`;
const FooterText = styled.p`
  font-size: 14px;
  color: #828282;
  font-weight: 600;
  font-family: "Raleway";
  text-align: center;
`;

interface SpacerProps {
  readonly enabled: boolean;
}

const Spacer = styled.div<SpacerProps>`
  display: flex;
  flex-direction: column;
  max-height: 460px;
  ${(props) =>
    props.enabled &&
    css`
      padding: 30px;
      max-height: 450px;
    `}
`;

const SwapCardContainer = styled.div<Props>`
  display: flex;
  width: 423px;
  flex-direction: column;
`;

export const SwapCardBody = styled.div<Props>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  min-width: 423px;
  max-height: 533px;
  margin: 0 8px;
  padding: 30px;

  background: #ffffff;
  border: 2px solid rgba(242, 242, 242, 0.1);
  border-radius: 24px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
  transition: height 200ms ease-in-out;
  ${(props) =>
    props.mode === "edit" &&
    css`
      border: 4px solid #333333;
    `}
  ${(props) =>
    props.mode === "locked" &&
    css`
      border: 4px solid transparent;
      background-image: linear-gradient(white, white),
        linear-gradient(67deg, rgba(24, 87, 242, 1) 0%, rgb(0 255 216) 100%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      padding: 0 0 30px 0;
    `}
  ${(props) =>
    props.mode === "locked-read-only" &&
    css`
      border: 4px solid transparent;
      background-image: linear-gradient(white, white),
        linear-gradient(290deg, rgba(24, 87, 242, 1) 0%, rgb(0 255 216) 100%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      padding: 0 0 30px 0;
    `}

  ${(props) =>
    props.swappingAnimation &&
    css`
      animation: gradient 5s ease infinite;
    `}
  
  /* background-size is required for the gradient animation */
  background-size: 400% 400%;
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const ItemCounter = styled.div`
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 700;
  color: #828282;
`;

const ItemCount = styled.span`
  color: #333333;
`;

interface SwapCardProps {
  participant: Participant | null;
  isSelf: boolean;
  name: string | null;
}

export function SwapCard({
  participant,
  isSelf,
  name,
}: SwapCardProps): React.ReactElement {
  const { state } = useAppContext();
  const { provider, loadWeb3Modal, logoutOfWeb3Modal } = useWeb3Modal();
  const isLocked = participant?.isLocked || false;

  let mode: Mode = "read-only";
  if (isSelf && isLocked) {
    mode = "locked";
  } else if (isSelf) {
    mode = "edit";
  } else if (!isSelf && isLocked) {
    mode = "locked-read-only";
  } else if (!isSelf) {
    mode = "read-only";
  }

  const counterparty: Participant | null =
    state.trade.participants.find(
      (p) => p.walletID !== participant?.walletID
    ) || null;

  const showConfirmBtn =
    (isSelf && state.selectedNfts.length > 0) ||
    (!isSelf && participant && participant.assets?.length > 0) ||
    (isSelf && counterparty && counterparty.assets?.length > 0);

  const showWalletBtn = isSelf && (state.walletID ?? "").length === 0;
  const showWalletPill = !showWalletBtn || !isSelf;

  const isOrderSigned = !!state.trade.order?.signature;
  const showSwapBtn =
    Boolean(isLocked && counterparty?.isLocked) &&
    (!isOrderSigned || participant?.isTaker) &&
    !state.trade.txHash;

  let footerText;

  if (participant && !isSelf && isLocked && !counterparty?.isLocked) {
    footerText = `${selectWalletName(
      state,
      counterparty?.walletID
    )} locked their assets and is ready to trade.`;
  } else if (
    showConfirmBtn &&
    participant &&
    isSelf &&
    isLocked &&
    !counterparty?.isLocked
  ) {
    footerText = `You can still edit your assets while waiting for ${selectWalletName(
      state,
      counterparty?.walletID
    )} to be ready`;
  } else if (showConfirmBtn && participant && isSelf && !isLocked) {
    footerText =
      "You can still review and confirm your trade at the next step. It does not cost any fees to confirm assets.";
  } else if (participant && !isSelf && !isLocked) {
    footerText = `Waiting for ${selectWalletName(
      state,
      participant?.walletID
    )} to select and confirm their assets`;
  }

  if (
    showSwapBtn &&
    state.trade.order?.makerAddress == participant?.walletID &&
    !state.trade.order?.signature
  ) {
    footerText =
      "Click Start Swap to beign the trade. Assets will not be transfered form your wallet until both parties swap.";
  } else if (showSwapBtn && !state.trade.order?.signature) {
    footerText = "Waiting for counterparty to start the swap";
  } else if (
    state.trade.order?.takerAddress == participant?.walletID &&
    showSwapBtn &&
    state.trade.order?.signature
  ) {
    footerText = "Start swap to complete the trade";
  }

  let innerFooterText = "";

  if (state.txHashStatus == TxHashStatus.PENDING) {
    innerFooterText = "Confirming transaction on the blockchain...";
  } else if (state.txHashStatus == TxHashStatus.CONFIRMED) {
    innerFooterText = "Transaction confirmed!";
  } else if (state.txHashStatus == TxHashStatus.FAILED) {
    innerFooterText = "Transaction failed";
  } else if (
    state.trade.order?.makerAddress == participant?.walletID &&
    isOrderSigned
  ) {
    innerFooterText = "Waiting for counterparty to complete the trade";
  } else if (participant?.isLocked) {
    innerFooterText = "Assets Confirmed";
  }

  const assetListSource =
    state.txHashStatus == TxHashStatus.CONFIRMED ? counterparty : participant;
  const totalAssets: number = assetListSource?.assets?.length ?? 0;

  return (
    <SwapCardContainer mode={mode}>
      <SwapCardBody
        mode={mode}
        swappingAnimation={state.txHashStatus === TxHashStatus.PENDING}
      >
        <Spacer enabled={isLocked}>
          <Row>
            <Heading1 grow={2}>
              {isSelf
                ? state.txHashStatus == TxHashStatus.CONFIRMED
                  ? "Received"
                  : "Send"
                : state.txHashStatus == TxHashStatus.CONFIRMED
                ? "Sent"
                : "Receive"}
            </Heading1>
            <Tooltip
              hasArrow
              label={
                isSelf
                  ? state.walletID != null &&
                    state.walletNames.get(state.walletID)
                    ? "Click to Disconnect Wallet"
                    : "Click to Connect Wallet"
                  : undefined
              }
              placement='auto'
            >
              <div>
                {showWalletBtn && (
                  <WalletButton
                    provider={provider}
                    loadWeb3Modal={loadWeb3Modal}
                    logoutOfWeb3Modal={logoutOfWeb3Modal}
                  />
                )}
                {showWalletPill && (
                  <Pill
                    status={participant ? "green" : "red"}
                    onClick={isSelf ? logoutOfWeb3Modal : undefined}
                    primary
                  >
                    {name
                      ? name
                      : !isSelf && !participant
                      ? "Waiting for Trader 2"
                      : "Not Connected"}
                  </Pill>
                )}
              </div>
            </Tooltip>
          </Row>
          <Row>
            <ItemCounter>
              Total:
              <ItemCount>
                {" " + totalAssets} item{totalAssets != 1 ? "s" : ""}
              </ItemCount>
            </ItemCounter>
          </Row>
          <SwapCardScrollableContent>
            {(assetListSource?.assets ?? [])
              .filter((asset: Asset) => asset.type != "ERC20")
              .map((asset: Asset) => (
                <SwapItem
                  asset={asset}
                  enabled={isSelf && !isLocked}
                  key={asset.tokenAddress + "_" + asset.tokenId}
                />
              ))}
          </SwapCardScrollableContent>
          {!isLocked && !isOrderSigned && (
            <SelectNftsButton
              isSelf={isSelf}
              walletID={participant?.walletID}
            />
          )}
        </Spacer>
        <InnerFooterText>{innerFooterText}</InnerFooterText>
      </SwapCardBody>
      <SwapCardFooter>
        {showSwapBtn && <StartSwapButton enabled={isSelf} isSelf={isSelf} />}
        {isSelf && showConfirmBtn && !isOrderSigned && (
          <ConfirmAssetsButton enabled={isSelf} participant={participant} />
        )}
        <FooterText>{footerText}</FooterText>
      </SwapCardFooter>
    </SwapCardContainer>
  );
}
