import { Tooltip, useClipboard } from "@chakra-ui/react";
import React from "react";
import { useAppContext } from "../hooks/useAppContext";
import { OnWalletConnect } from "./OnWalletConnect";
import { Pill } from "./Pill.react";

export default (): React.ReactElement => {
  const { state } = useAppContext();
  const tradeShortLink = `${window.location.origin}/${state.trade.tradeID}`;
  const { hasCopied, onCopy } = useClipboard(tradeShortLink, 1200);

  return (
    <>
      {state.walletID != null && state.trade.tradeID != null && (
        <>
          <Tooltip
            hasArrow
            label={hasCopied ? "Copied" : "Click to Copy"}
            placement='top'
            closeDelay={500}
          >
            <div>
              <Pill pretty info onClick={onCopy}>
                {tradeShortLink}
              </Pill>
            </div>
          </Tooltip>
          <OnWalletConnect />
        </>
      )}
    </>
  );
};
