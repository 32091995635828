import React from "react";
import styled from "styled-components";
import { closeNftModal, useAppContext } from "../hooks/useAppContext";
import { useUpdateTradeAssets } from "../hooks/useUpdateTradeAssets";
import { Asset } from "../interfaces";

const IconContainer = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 16px;
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid #333333;
  justify-content: center;
  align-items: center;
  color: #333333;
  font-size: 16px;
  font-weight: 900;
  font-family: "Raleway";
`;

const BarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 810px;
  flex-shrink: 0;
  overflow: visible;
  min-height: 52px;
  align-items: center;
`;

const Bar = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-self: stretch;
  overflow-x: auto;
`;

const SelectButton = styled.button`
  max-width: 235px;
  height: 43px;
  background: #2f80ed;
  border: none;
  border-radius: 36px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  font-family: "Raleway";
  padding: 0 20px;
  transition: filter 150ms;
  align-self: flex-end;

  &:hover,
  &:focus {
    filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.25));
    cursor: pointer;
    border: none;
  }

  &:active {
    filter: none;
  }

  &:disabled {
    background: #ccc;
    color: #ffffff;
    cursor: default;
    filter: none;
  }
`;

const MAX_COUNT = 8;
const MAX_DISPLAY_COUNT_ICON = 1000;

export function NftModalBar(): React.ReactElement {
  const { dispatch, state } = useAppContext();
  const selectedNfts = state.selectedNfts;
  const { updateTradeAssets, loading } = useUpdateTradeAssets({
    tradeId: state.trade.tradeID,
  });

  if (state.walletID !== state.nftModalWalletID) {
    return (
      <BarContainer>
        <Bar />
        <SelectButton
          onClick={() => {
            dispatch(closeNftModal());
          }}
        >
          Done
        </SelectButton>
      </BarContainer>
    );
  }

  return (
    <BarContainer>
      <Bar>
        {selectedNfts.slice(0, MAX_COUNT).map((asset: Asset) => (
          <IconContainer key={asset.tokenAddress + "-" + asset.tokenId}>
            {asset.image && (
              <img
                alt={asset.tokenId}
                width='100%'
                height='100%'
                src={asset.image}
              />
            )}
          </IconContainer>
        ))}
        {selectedNfts.length > MAX_COUNT && (
          <IconContainer>
            {Math.min(MAX_DISPLAY_COUNT_ICON, selectedNfts.length - MAX_COUNT)}+
          </IconContainer>
        )}
      </Bar>
      <SelectButton
        disabled={selectedNfts.length === 0 || loading}
        onClick={() => {
          dispatch(closeNftModal());
          updateTradeAssets({
            data: {
              // tslint:disable-next-line
              assets: state.selectedNfts,
              // tslint:disable-next-line
              participantToken: state.participantToken,
            },
          });
        }}
      >
        {selectedNfts.length === 0
          ? "Select NFTs"
          : `${selectedNfts.length} NFT${
              selectedNfts.length != 1 ? "s" : ""
            } Selected`}
      </SelectButton>
    </BarContainer>
  );
}
