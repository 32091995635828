import styled from "styled-components";

export const NftsModalScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-left: -8px;
  position: relative;
  flex-flow: row;
  flex-wrap: wrap;
  padding-top: 4px;
  height: 380px;
  padding-left: 12px;
`;
