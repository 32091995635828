import styled, { css } from "styled-components";

export const SwapCardScrollableContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 8px 0;
  position: relative;
`;

interface PillProps {
  readonly info?: boolean;
  readonly primary?: boolean;
}

export const Pill = styled.div<PillProps>`
  box-sizing: border-box;
  border-radius: 24px;
  height: 29px;
  padding: 8px 14px;
  font-size: 1em;
  display: flex;
  align-items: center;
  align-self: center;
  line-height: 24px;

  ${(props) =>
    props.info &&
    css`
      background: #fff;
      color: #333333;
    `}

  ${(props) =>
    props.primary &&
    css`
      background: rgb(242, 242, 242);
      color: #333333;
    `}
`;
