import { useMemo } from "react";
import { Body } from "../components/Body.react";
import { CardInfoText } from "../components/CardInfoText.react";
import { Column } from "../components/Column.react";
import CopyRoomLinkPill from "../components/CopyRoomLinkPill";
import { Heading1, Heading2 } from "../components/Heading.react";
import { Row } from "../components/Row.react";
import { SelectNftsModal } from "../components/SelectNftsModal.react";
import { SwapCard } from "../components/SwapCard.react";
import {
  selectWalletName,
  TxHashStatus,
  useAppContext,
} from "../hooks/useAppContext";
import { Participant } from "../interfaces";

export default function Trade(): JSX.Element {
  const { state } = useAppContext();

  const selfParticipant =
    state.trade?.participants.find(
      (participant: Participant) => participant.walletID == state.walletID
    ) ?? null;
  const counterParty =
    state.trade?.participants.find(
      (participant: Participant) => participant.walletID != state.walletID
    ) ?? null;
  const counterPartyWalletName = selectWalletName(
    state,
    counterParty?.walletID
  );
  const { txHashStatus, trade } = state;

  const { heading1Text, heading2Text } = useMemo(() => {
    let heading1Text = "";
    let heading2Text = "";

    if (!state.walletID) {
      heading1Text = "Connect your wallet to start";
      heading2Text = `Once you connect your wallet a new trade link\nwill be generated`;
    } else if (
      selfParticipant &&
      selfParticipant.isLocked &&
      counterParty &&
      !counterParty.isLocked
    ) {
      heading1Text = "Assets confirmed";
      heading2Text = `Waiting for ${counterPartyWalletName} to select and confirm their assets`;
    } else if (
      selfParticipant &&
      selfParticipant.isLocked &&
      counterParty &&
      counterParty.isLocked
    ) {
      if (txHashStatus == TxHashStatus.CONFIRMED) {
        heading1Text = "Swap complete!";
        heading2Text = "Enjoy your new NFTs";
      } else if (txHashStatus == TxHashStatus.FAILED) {
        heading1Text = "Swap transaction failed.";
        heading2Text = "No NFTs were swapped. Try the transaction again.";
      } else if (txHashStatus == TxHashStatus.PENDING) {
        heading1Text = "Transaction is being broadcasted";
        heading2Text =
          "Once the ethereum blockchain confirms the transaction\nthe trade will be complete. This can take a few minutes.";
      } else if (
        trade.order &&
        !trade.order.signature &&
        selfParticipant.isMaker
      ) {
        heading1Text = "Ready to swap";
        heading2Text =
          "Tapping 'Start Swap' will confirm and approve the transaction";
      } else if (
        trade.order &&
        trade.order.signature &&
        selfParticipant.isMaker
      ) {
        heading1Text = "Swap confirmed";
        heading2Text = `Once ${counterPartyWalletName} confirms, the swap will be complete`;
      } else if (
        trade.order &&
        !trade.order.signature &&
        selfParticipant.isTaker
      ) {
        heading1Text = "Waiting for maker to confirm";
        heading2Text = `Once ${counterPartyWalletName} confirms, you'll be able to approve the trade`;
      } else if (
        trade.order &&
        trade.order.signature &&
        selfParticipant.isTaker &&
        txHashStatus == null
      ) {
        heading1Text = "Ready to swap";
        heading2Text =
          "Tapping 'Start Swap' will confirm and approve the transaction";
      }
    } else if (selfParticipant && selfParticipant.assets.length >= 1) {
      heading1Text = "Select and confirm your assets.";
      heading2Text =
        "This lets each trader known that you're ready and\nagreeable to the terms of the trade.";
    } else if (trade.participants.length > 1) {
      heading1Text = "Select and confirm your assets.";
      heading2Text =
        "This lets each trader know that you're ready and agree to the terms of the trade.";
    } else if (trade.tradeID) {
      heading1Text = "Welcome to your trade room.";
      heading2Text = "Send the invite link to whom you'd like to trade with.";
    }
    return { heading1Text, heading2Text };
  }, [
    counterParty,
    counterPartyWalletName,
    selfParticipant,
    state.walletID,
    trade.order,
    trade.participants.length,
    trade.tradeID,
    txHashStatus,
  ]);

  return (
    <Body>
      <Column style={{ minHeight: 140 }}>
        <Row>
          <Heading1>{heading1Text}</Heading1>
        </Row>
        <Row>
          <Heading2>{heading2Text}</Heading2>
        </Row>
      </Column>
      <Row>
        <CopyRoomLinkPill />
      </Row>
      <Row></Row>
      <Row flexWrap>
        <Column marginRight={64}>
          <CardInfoText>Counterparty can see your activity</CardInfoText>
          <SwapCard
            participant={selfParticipant}
            name={selectWalletName(state, selfParticipant?.walletID)}
            isSelf={true}
          />
        </Column>
        <Column>
          <CardInfoText>View only</CardInfoText>
          <SwapCard
            participant={counterParty}
            name={selectWalletName(state, counterParty?.walletID)}
            isSelf={false}
          />
        </Column>
      </Row>
      <SelectNftsModal />
    </Body>
  );
}
