import useAxios from "axios-hooks";
import { Trade } from "../interfaces";
import { useAppContext } from "./useAppContext";

export function useSubmitOrderSignature({ signature }: { signature: string }) {
  const { state } = useAppContext();
  const url = `${process.env.REACT_APP_API_HOST}/trades/${state.trade.tradeID}/sign`;
  const [{ data, loading, error: err }, submitOrderSignature] = useAxios<Trade>(
    {
      url,
      method: "POST",
      withCredentials: true,
      data: {
        participantToken: state.participantToken,
        tradeID: state.trade.tradeID,
        signature,
      },
    },
    { manual: true }
  );

  return { data, err, loading, submitOrderSignature };
}
