import * as React from "react";
import { Route, Routes } from "react-router-dom";
import About from "./About";
import App from "./App";
import Trade from "./Trade";

export default function AppRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path='/' element={<App />}>
        <Route index element={<Trade />} />
        <Route path=':tradeId' element={<Trade />} />
      </Route>
      <Route path='/about' element={<About />} />
      <Route
        path='*'
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  );
}
