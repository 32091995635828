import styled from "styled-components";

interface Props {
  readonly flexWrap?: boolean;
}

export const Row = styled.div<Props>`
  display: flex;
  flex-direction: row;
  margin: 0 0 16px 0;
  flex-wrap: ${(props) => (props.flexWrap ? "wrap" : "inherit")};
`;
