import type { Web3Provider } from "@ethersproject/providers";
import { NftSwapConfig, NftSwapV3 } from "@traderxyz/nft-swap-sdk";
import { useEffect, useState } from "react";
import { useAppContext } from "../hooks/useAppContext";
import useWeb3Modal from "../hooks/useWeb3Modal";

export function useNftSwapSdk(): { nftSwapSdk?: NftSwapV3 } {
  const [nftSwapSdk, setNftSwapSdk] = useState<NftSwapV3>();
  const { provider: _provider } = useWeb3Modal();
  const provider = _provider as unknown as Web3Provider;
  const {
    state: {
      trade: { chainID, exchangeContractAddress },
    },
  } = useAppContext();

  useEffect(() => {
    if (!provider) {
      return;
    }
    if (typeof chainID == undefined) {
      return;
    }
    if (!exchangeContractAddress) {
      return;
    }
    const config: NftSwapConfig = {
      exchangeContractAddress,
    };

    const nftSwapSdk: NftSwapV3 = new NftSwapV3(
      provider,
      provider.getSigner(),
      chainID,
      config
    );
    setNftSwapSdk(nftSwapSdk);
  }, [provider, chainID, exchangeContractAddress]);

  return { nftSwapSdk };
}
