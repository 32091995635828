import useAxios from "axios-hooks";
import { Trade } from "../interfaces";

export function useUpdateTxHash({
  tradeId,
  txHash,
}: {
  tradeId: string | null;
  txHash: string | null;
}) {
  const url = `${process.env.REACT_APP_API_HOST}/trades/${tradeId}/txHash`;
  const [{ data, loading, error: err }, updateTxHash] = useAxios<Trade>(
    {
      url,
      method: "POST",
      withCredentials: true,
      data: { txHash },
    },
    { manual: true }
  );

  return { data, err, loading, updateTxHash };
}
