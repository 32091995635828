import { Button } from "@chakra-ui/react";

// interface CreateTradeButtonProps {}

export default function CreateTradeButton() {
  return (
    <Button
      size='md'
      height='55px'
      width='216px'
      bg='#333'
      fontWeight='bold'
      fontSize='20px'
      color='#fff'
      _hover={{ bg: "#333" }}
      borderRadius='36px'
      _active={{ bg: "#333" }}
      _focus={{ border: "0" }}
    >
      Create trade room
    </Button>
  );
}
