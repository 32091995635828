import React from "react";
import { openNftModal, useAppContext } from "../hooks/useAppContext";
import { useRequireWallet } from "../hooks/useRequireWallet";
import { address } from "../interfaces";
import { Button } from "./Button.react";

export function SelectNftsButton({
  walletID,
  isSelf,
}: {
  walletID?: address;
  isSelf: boolean;
}): React.ReactElement {
  const { dispatch, state } = useAppContext();
  const onClick = useRequireWallet(() => dispatch(openNftModal(walletID)));
  const label = isSelf ? "Select NFTs" : "View NFTs";

  // TODO: mention hiding this button for the counterparty
  return (
    <Button
      light
      large
      left
      title={label}
      onClick={onClick}
      type='button'
      disabled={walletID == null}
      raiseOnHover={{ opacity: 0.15, offsetX: 0, offsetY: 1, blurRadius: 4 }}
    >
      {label}
    </Button>
  );
}
