import React, { ReactElement, useContext, useReducer, useState } from "react";
import Web3Modal from "web3modal";
import { address, Asset, Participant, Trade } from "../interfaces";
import { AlchemyNftMetadata } from "./AlchemyNftMetadata";
import { AlchemyWalletNfts } from "./AlchemyWalletNfts";

export interface AppStateInterface {
  nftModalWalletID: address | null;
  walletID: address | null;
  participantToken: string | null;
  walletNames: Map<address, string>;
  trade: Trade;
  selectedNfts: Asset[];
  walletAddrToNfts: Map<address, Array<AlchemyNftMetadata.RootObject>>;
  txHashStatus: TxHashStatus | null;
  assetCache: Map<string, Asset>;
}

enum ActionName {
  OPEN_NFT_MODAL,
  CLOSE_NFT_MODAL,
  SELECT_NFT,
  UNSELECT_NFT,
  UPDATE_WALLET_ID,
  ADD_WALLET_NAME,
  REMOVE_WALLET_NAME,
  UPDATE_TRADE,
  UPDATE_PARTICIPANT_TOKEN,
  SET_SELECTED_NFTS,
  UPDATE_TX_HASH_STATUS,
  UPDATE_ASSET_CACHE,
  UPATE_WALLET_NFTS,
}

export enum TxHashStatus {
  PENDING,
  FAILED,
  CONFIRMED,
}

interface DispatchActionType {
  type: ActionName;
  payload?: any;
}

interface AppContextInterface {
  state: AppStateInterface;
  dispatch: React.Dispatch<DispatchActionType>;
}

const initialState = {
  nftModalWalletID: null,
  walletID: null,
  participantToken: null,
  walletNames: new Map<address, string>(),
  txHashStatus: null,
  trade: {
    chainID: undefined,
    tradeID: null,
    order: {
      makerAddress: "",
      takerAddress: "",
      feeRecipientAddress: "",
      senderAddress: "",
      makerAssetAmount: "",
      takerAssetAmount: "",
      makerFee: "",
      takerFee: "",
      expirationTimeSeconds: "",
      salt: "",
      makerAssetData: "",
      takerAssetData: "",
      makerFeeAssetData: "",
      takerFeeAssetData: "",
      signature: "",
    },
    participants: new Array<Participant>(),
    exchangeContractAddress: "",
    ts: 0,
  },
  selectedNfts: new Array<Asset>(),
  walletAddrToNfts: new Map<address, AlchemyNftMetadata.RootObject[]>(),
  assetCache: new Map<string, Asset>(),
};

// Selectors
export const selectWalletName = (
  state: AppStateInterface,
  walletID?: address
): string | null => {
  return state.walletNames.get(walletID || "") || null;
};

export const selectParticipant = (
  state: AppStateInterface,
  walletID: string
): Participant | null => {
  return state.trade.participants.find((p) => p.walletID == walletID) || null;
};

export const selectAssetFromCache = (
  state: AppStateInterface,
  key: string
): Asset | null => {
  return state.assetCache.get(key) || null;
};

// Action Creators
export const updateParticipantToken = (token: string) => {
  return {
    type: ActionName.UPDATE_PARTICIPANT_TOKEN,
    payload: {
      token,
    },
  };
};

export const openNftModal = (walletID: address | undefined) => {
  return {
    type: ActionName.OPEN_NFT_MODAL,
    payload: {
      walletID,
    },
  };
};

export const closeNftModal = () => {
  return {
    type: ActionName.CLOSE_NFT_MODAL,
  };
};

export const setSelectedNfts = (assets: Array<Asset>) => {
  return {
    type: ActionName.SET_SELECTED_NFTS,
    payload: {
      assets,
    },
  };
};

export const selectNft = (asset: Asset) => {
  return {
    type: ActionName.SELECT_NFT,
    payload: {
      asset,
    },
  };
};

export const unselectNft = (asset: Asset) => {
  return {
    type: ActionName.UNSELECT_NFT,
    payload: {
      asset,
    },
  };
};

export const updateWalletID = (walletID: string) => {
  return {
    type: ActionName.UPDATE_WALLET_ID,
    payload: {
      walletID,
    },
  };
};

export const addWalletName = (walletID: string, name: string) => {
  return {
    type: ActionName.ADD_WALLET_NAME,
    payload: {
      walletID,
      name,
    },
  };
};

export const removeWalletName = (walletID: string) => {
  return {
    type: ActionName.REMOVE_WALLET_NAME,
    payload: {
      walletID,
    },
  };
};

export const updateTrade = (trade: Trade) => {
  return {
    type: ActionName.UPDATE_TRADE,
    payload: {
      trade,
    },
  };
};

export const updateTxHashStatus = (status: TxHashStatus) => {
  return {
    type: ActionName.UPDATE_TX_HASH_STATUS,
    payload: {
      status,
    },
  };
};

export const updateAssetCache = (cacheKey: string, asset: Asset | null) => {
  return {
    type: ActionName.UPDATE_ASSET_CACHE,
    payload: {
      cacheKey,
      asset,
    },
  };
};

export const updateWalletNfts = (
  nfts: Array<AlchemyWalletNfts.OwnedNft>,
  walletID?: address
) => {
  return {
    type: ActionName.UPATE_WALLET_NFTS,
    payload: {
      nfts,
      walletID,
    },
  };
};

function filterNFTByAction(action: DispatchActionType) {
  return (asset: Asset) => {
    if (
      asset.tokenId == action.payload.asset.tokenId &&
      asset.tokenAddress == action.payload.asset.tokenAddress
    ) {
      return false;
    }
    return {
      ...asset,
    };
  };
}

function reducer(state: AppStateInterface, action: DispatchActionType) {
  switch (action.type) {
    case ActionName.UPDATE_PARTICIPANT_TOKEN:
      return {
        ...state,
        participantToken: action.payload.token,
      };
    case ActionName.OPEN_NFT_MODAL:
      return {
        ...state,
        nftModalWalletID: action.payload?.walletID ?? null,
      };
    case ActionName.CLOSE_NFT_MODAL:
      return {
        ...state,
        nftModalWalletID: null,
      };
    case ActionName.SELECT_NFT:
      return {
        ...state,
        selectedNfts: [
          ...state.selectedNfts.filter(filterNFTByAction(action)),
          action.payload?.asset,
        ],
      };
    case ActionName.SET_SELECTED_NFTS:
      return {
        ...state,
        selectedNfts: [...action.payload.assets],
      };
    case ActionName.UNSELECT_NFT: {
      return {
        ...state,
        selectedNfts: state.selectedNfts.filter(filterNFTByAction(action)),
      };
    }
    case ActionName.UPDATE_WALLET_ID:
      return {
        ...state,
        walletID: action.payload.walletID,
      };
    case ActionName.ADD_WALLET_NAME:
      return {
        ...state,
        walletNames: new Map(state.walletNames).set(
          action.payload.walletID,
          action.payload.name
        ),
      };
    case ActionName.REMOVE_WALLET_NAME:
      const walletNames = new Map(state.walletNames);
      walletNames.delete(action.payload.walletID);
      return {
        ...state,
        walletNames,
      };
    case ActionName.UPDATE_TRADE: {
      return {
        ...state,
        trade: action.payload.trade,
      };
    }
    case ActionName.UPDATE_TX_HASH_STATUS: {
      return {
        ...state,
        txHashStatus: action.payload.status,
      };
    }
    case ActionName.UPDATE_ASSET_CACHE: {
      return {
        ...state,
        assetCache: new Map(state.assetCache).set(
          action.payload.cacheKey,
          action.payload.asset
        ),
      };
    }
    case ActionName.UPATE_WALLET_NFTS: {
      return {
        ...state,
        walletAddrToNfts: new Map(state.walletAddrToNfts).set(
          action.payload.walletID || state.walletID,
          [...action.payload.nfts]
        ),
      };
    }
    default:
      throw new Error();
  }
}

export const AppContext: React.Context<AppContextInterface> =
  React.createContext({} as AppContextInterface);

export function useAppContext(): AppContextInterface {
  return useContext(AppContext);
}

export const Web3ModalContext = React.createContext<{
  web3Modal: Web3Modal | null;
  setWeb3Modal: React.Dispatch<React.SetStateAction<Web3Modal | null>> | null;
}>({
  web3Modal: null,
  setWeb3Modal: null,
});

export function AppContextProvider({ children }: { children: ReactElement }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [web3Modal, setWeb3Modal] = useState<Web3Modal | null>(null);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Web3ModalContext.Provider value={{ web3Modal, setWeb3Modal }}>
        {children}
      </Web3ModalContext.Provider>
    </AppContext.Provider>
  );
}
