import styled, { css } from "styled-components";

interface ButtonProps {
  readonly light?: boolean;
  readonly dark?: boolean;
  readonly gradient?: boolean;
  readonly gradientReverse?: boolean;
  readonly gradientOutline?: boolean;
  readonly gradientOutlineReverse?: boolean;
  readonly hidden?: boolean;
  readonly large?: boolean;
  readonly left?: boolean;
  readonly width?: number;
  readonly height?: number;
  readonly borderRadius?: number;
  readonly disabled?: boolean;
  readonly marginBottom?: number;
  readonly raiseOnHover?: {
    opacity: number;
    offsetX: number;
    offsetY: number;
    blurRadius: number;
  };
}

export const Button = styled.button<ButtonProps>`
  box-sizing: border-box;

  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 8px;
  border-radius: 16px;
  ${(props) =>
    props.borderRadius &&
    css`
      border-radius: ${props.borderRadius}px;
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
    ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}
  background: #f2f2f2;
  color: #cccccc;

  font-family: Raleway;

  ${(props) =>
    props.left &&
    css`
      text-align: left;
    `}
  ${(props) =>
    props.large &&
    css`
      padding: 16px;
      font-weight: bold;
      font-size: 14px;
    `}

  ${(props) =>
    props.dark &&
    css`
      background: #383838;
      color: #ffffff;
      ${!props.disabled &&
      css`
        &:active {
          color: #e0e0e0;
        }
      `}
    `}
  

  ${(props) =>
    props.light &&
    css`
      background: #ffffff;
      color: #333333;
      border: 2px solid #e0e0e0;
      ${!props.disabled &&
      css`
        &:active {
          color: #e0e0e0;
        }
      `}
    `}

  ${(props) =>
    props.hidden &&
    css`
      border: none;
      outline: none;
    `}
  
  ${(props) =>
    props.gradientOutline &&
    css`
      border: 4px solid transparent;
      background-image: linear-gradient(white, white),
        linear-gradient(67deg, rgba(24, 87, 242, 1) 0%, rgb(0 255 216) 100%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      padding: 0px;
      height: 50px;
      color: #1857f2;
    `}
  
  ${(props) =>
    props.gradientOutlineReverse &&
    css`
      border: 4px solid transparent;
      background-image: linear-gradient(white, white),
        linear-gradient(290deg, rgba(24, 87, 242, 1) 0%, rgb(0 255 216) 100%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      padding: 0px;
      height: 50px;
      color: #1857f2;
    `}
  
  ${(props) =>
    props.gradient &&
    css`
      border: none;
      background-image: linear-gradient(
        60deg,
        rgba(24, 87, 242, 1) 30%,
        rgb(0 255 216) 115%
      );
      background-origin: border-box;
      background-clip: content-box, border-box;
      padding: 0px;
      height: 50px;
      color: #ffffff;
    `}
  ${(props) =>
    props.gradientReverse &&
    css`
      border: none;
      background-image: linear-gradient(
        313deg,
        rgba(24, 87, 242, 1) 30%,
        rgb(0 255 216) 115%
      );
      background-origin: border-box;
      background-clip: content-box, border-box;
      padding: 0px;
      height: 50px;
      color: #ffffff;
    `}

    ${({ raiseOnHover, disabled }) =>
    raiseOnHover != null &&
    disabled !== true &&
    css`
      &:focus,
      &:hover {
        filter: drop-shadow(
          ${raiseOnHover.offsetX}px ${raiseOnHover.offsetY}px
            ${raiseOnHover.blurRadius}px rgba(0, 0, 0, ${raiseOnHover.opacity})
        );
      }
    `}

  &:disabled {
    cursor: default;
  }
`;
