import { useToast } from "@chakra-ui/react";
import { Web3Provider } from "@ethersproject/providers";
import React, { useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import {
  addWalletName,
  updateTrade,
  useAppContext,
} from "../hooks/useAppContext";
import { usePreloadNftMetadata } from "../hooks/usePreloadNftMetadata";
import useWeb3Modal from "../hooks/useWeb3Modal";
import { CreateOrJoinTradeResponse, Participant } from "../interfaces";

export function useUpdateTrade(tradeData: CreateOrJoinTradeResponse | null) {
  const { provider } = useWeb3Modal();
  const { state, dispatch } = useAppContext();
  const toast = useToast();

  useEffect(() => {
    if (tradeData == null) {
      return;
    }

    dispatch(updateTrade(tradeData));

    // update counter parties wallet info
    tradeData.participants.forEach(async (participant: Participant) => {
      if (participant.walletID == state.walletID) {
        return;
      }
      // we already have the wallet info, skip adding it again
      if (state.walletNames.has(participant.walletID)) {
        return;
      }
      const walletID = participant.walletID;
      const _provider = provider as unknown as Web3Provider;

      if (!_provider) {
        return;
      }
      let name = await _provider.lookupAddress(walletID);
      // Render either the ENS name or the shortened account address.
      if (!name) {
        name = walletID.substring(0, 6) + "..." + walletID.substring(36);
      }
      dispatch(addWalletName(walletID, name));
      toast({
        title: `${name} has joined the trade!`,
        description: "You can view their nfts and trade with them!",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    });
  }, [dispatch, provider, state.walletID, tradeData]);
}

export function OnWalletConnect(): React.ReactElement {
  const { state } = useAppContext();
  const { preloadWalletNfts, preloadNftMetadata } = usePreloadNftMetadata({
    ownerWalletAddr: state.walletID!,
  });
  if (state.walletID == null && state.trade.tradeID == null) {
    console.error("wc", state);
    throw new Error(
      "Wallet be connected and in a trade before render/mount of wallet connect hooks"
    );
  }

  const socketUrl = `${process.env.REACT_APP_API_WS_HOST}/trades/${state.trade.tradeID}/websocket`;

  const [res, setRes] = useState<CreateOrJoinTradeResponse | null>(null);
  const { lastMessage, readyState } = useWebSocket(socketUrl, {
    reconnectAttempts: 10,
    reconnectInterval: 3000,
  });

  useUpdateTrade(res);

  useEffect(() => {
    preloadWalletNfts();
  }, [preloadWalletNfts]);

  const wallet =
    (state.walletID != null
      ? state.walletAddrToNfts.get(state.walletID)
      : null) || [];
  const tokenId = wallet[0]?.id?.tokenId;
  useEffect(() => {
    if (tokenId) {
      return preloadNftMetadata();
    }
  }, [preloadNftMetadata, tokenId]);

  useEffect(() => {
    //
    if (!lastMessage) {
      return;
    }
    try {
      const nextRes = JSON.parse(
        lastMessage?.data
      ) as CreateOrJoinTradeResponse | null;
      if (nextRes?.tradeID && nextRes?.participants) {
        setRes(nextRes);
      } else if (!nextRes?.tradeID) {
        console.error("unhandled payload exception", nextRes);
      } else {
      }
    } catch (e) {
      console.error("unable to parse", e);
    }
  }, [lastMessage, readyState]);

  return <></>;
}
