import {
  NftSwapV3,
  SupportedTokenTypes,
  SwappableAsset,
} from "@traderxyz/nft-swap-sdk";
import React from "react";
import { useAppContext } from "../hooks/useAppContext";
import { useLockTrade } from "../hooks/useLockTrade";
import { useNftSwapSdk } from "../hooks/useNftSwapSdk";
import { useUnlockTrade } from "../hooks/useUnlockTrade";
import { Asset, Participant } from "../interfaces";
import { Button } from "./Button.react";

export function ConfirmAssetsButton({
  enabled,
  participant,
}: {
  enabled: boolean;
  participant?: Participant | null;
}): React.ReactElement {
  const { state } = useAppContext();
  const { nftSwapSdk }: { nftSwapSdk?: NftSwapV3 } = useNftSwapSdk();
  const { lockTrade } = useLockTrade({
    participantToken: state.participantToken,
    tradeId: state.trade.tradeID,
  });
  const { unlockTrade } = useUnlockTrade({
    participantToken: state.participantToken,
    tradeId: state.trade.tradeID,
  });
  const isLocked = participant?.isLocked || false;

  interface Approval {
    asset: SwappableAsset;
    approved: boolean;
  }

  async function getApprovals() {
    if (!participant) {
      return;
    }

    const approvals: Array<Approval> = await Promise.all(
      participant?.assets.map(async function (asset: Asset) {
        let swappableAsset: SwappableAsset;
        switch (asset.type) {
          case "ERC20":
            swappableAsset = {
              type: SupportedTokenTypes.ERC20,
              tokenAddress: asset.tokenAddress,
              amount: asset.amount || "",
            };
            break;
          case "ERC721":
            swappableAsset = {
              type: SupportedTokenTypes.ERC721,
              tokenAddress: asset.tokenAddress,
              tokenId: asset.tokenId || "",
            };
            break;
          case "ERC1155":
            swappableAsset = {
              type: SupportedTokenTypes.ERC1155,
              tokenAddress: asset.tokenAddress,
              tokenId: asset.tokenId || "",
            };
            break;
          default:
            throw new Error("Invalid token type");
        }

        const approvalStatus = await nftSwapSdk?.loadApprovalStatus(
          swappableAsset,
          state.walletID || ""
        );
        return {
          asset: swappableAsset,
          approved: approvalStatus?.contractApproved || false,
        };
      })
    );

    const newApprovals = await Promise.all(
      approvals
        .filter((approval: Approval) => !approval.approved)
        .map(async function (approval: Approval) {
          return nftSwapSdk?.approveTokenOrNftByAsset(
            approval.asset,
            state.walletID || ""
          );
        })
    );

    return true;
  }

  const toggleLock = async function () {
    if (!isLocked) {
      if (await getApprovals()) {
        lockTrade();
      }
    } else {
      unlockTrade();
    }
  };

  return (
    <Button
      dark={!isLocked}
      gradientOutline={isLocked && enabled}
      gradientOutlineReverse={isLocked && !enabled}
      large
      title={isLocked ? "Edit Assets" : "Confirm Assets"}
      onClick={() => toggleLock()}
      disabled={!enabled}
      borderRadius={92}
      type='button'
      raiseOnHover={{ opacity: 0.35, offsetX: 0, offsetY: 1, blurRadius: 6 }}
    >
      {isLocked ? "Edit Assets" : "Confirm Assets"}
    </Button>
  );
}
