import { Dispatch, useCallback, useEffect } from "react";
import { address, Asset } from "../interfaces";
import { AlchemyNftMetadata } from "./AlchemyNftMetadata";
import { AlchemyWalletNfts } from "./AlchemyWalletNfts";
import {
  updateAssetCache,
  updateWalletNfts,
  useAppContext,
} from "./useAppContext";

async function getWalletNfts({
  ownerWalletAddr,
}: {
  ownerWalletAddr: string;
}): Promise<{ nfts: Array<AlchemyWalletNfts.OwnedNft> }> {
  const baseURL = `${process.env.REACT_APP_API_HOST}/nfts/getNFTs/`;
  // Replace with the wallet address you want to query:
  const url = `${baseURL}?owner=${ownerWalletAddr}`;
  const res = await fetch(url);
  const data = await res.json();
  return { nfts: data?.ownedNfts || [] };
}

async function createAsset(
  data: AlchemyNftMetadata.RootObject,
  {
    chainID,
    contractAddr,
    tokenId,
  }: {
    chainID: number | undefined;
    contractAddr: string;
    tokenId: string;
  }
): Promise<Asset> {
  const shortAddress =
    data?.id.tokenId.substring(0, 6) +
    "..." +
    data?.id.tokenId.substring(30, 36);
  let assetName = data?.title || shortAddress;
  const tokenType = data?.id.tokenMetadata.tokenType;
  const media = data?.media.find((media) => {
    return (
      media.gateway?.indexOf("https:") != -1 ||
      media.uri?.gateway?.indexOf("https:") != -1
    );
  });
  let img = media?.gateway != null ? media?.gateway : media?.uri?.gateway;

  if (!img && data?.tokenUri.gateway) {
    let url = data?.tokenUri.gateway;
    if (chainID == 4) {
      url = url.replace("api", "rinkeby-api");
    }
    const res = await fetch(url);
    const json = await res.json();
    assetName = json.name || assetName;
    img = json.image || "";
  }

  return {
    tokenId,
    tokenAddress: contractAddr,
    type: tokenType || "",
    image: img || "",
    name: assetName,
  };
}

async function fetchNFTMetadata({
  dispatch,
  chainID,
  contractAddr = "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d",
  tokenId = "2",
}: {
  dispatch: Dispatch<any>;
  chainID: number | undefined;
  contractAddr: string;
  tokenId: string;
}) {
  const baseURL = `${process.env.REACT_APP_API_HOST}/nfts/getNFTMetadata`;
  const alchemyUrl = `${baseURL}?contractAddress=${contractAddr}&tokenId=${tokenId}`;

  const res = await fetch(alchemyUrl);
  const data = await res.json();
  const asset = await createAsset(data, { chainID, tokenId, contractAddr });
  dispatch(updateAssetCache(`${contractAddr}:${tokenId}`, asset));
}

export function usePreloadNftMetadata({
  ownerWalletAddr = "0xF5FFF32CF83A1A614e15F25Ce55B0c0A6b5F8F2c",
}) {
  const { dispatch, state } = useAppContext();

  const preloadWalletNfts = useCallback(async () => {
    if (ownerWalletAddr == null) return;
    const { nfts } = await getWalletNfts({ ownerWalletAddr });
    dispatch(updateWalletNfts(nfts, ownerWalletAddr));
  }, [dispatch, ownerWalletAddr]);

  useEffect(() => {
    preloadWalletNfts();
  }, [preloadWalletNfts]);

  const walletNFTs = state.walletAddrToNfts.get(ownerWalletAddr);
  const chainID = state.trade.chainID;

  const preloadNftMetadata = useCallback(
    function () {
      const timeouts = walletNFTs?.map(
        (nft: AlchemyNftMetadata.RootObject, i) => {
          const shortAddress =
            nft?.id.tokenId.substring(0, 6) +
            "..." +
            nft?.id.tokenId.substring(30, 36);
          const assetName = nft?.title || shortAddress;
          const img = nft?.media[0].uri?.gateway ?? nft?.media[0]?.gateway;
          const isMissingMetadata = assetName == null || img == null;
          const timeout =
            isMissingMetadata && chainID == 4 ? 1200 * i : i % 200;

          return setTimeout(async () => {
            if (!isMissingMetadata) {
              const asset = await createAsset(nft, {
                chainID,
                contractAddr: nft.contract.address,
                tokenId: nft.id.tokenId,
              });
              dispatch(
                updateAssetCache(
                  `${nft.contract.address}:${nft.id.tokenId}`,
                  asset
                )
              );
            } else {
              fetchNFTMetadata({
                dispatch,
                chainID: chainID,
                contractAddr: nft.contract.address,
                tokenId: nft.id.tokenId,
              });
            }
          }, timeout);
        }
      );

      return () => {
        timeouts?.map((timeoutID) => window.clearTimeout(timeoutID));
      };
    },
    [walletNFTs, dispatch, chainID]
  );

  const refreshNftMetadata = useCallback(
    async ({
      contractAddr,
      tokenId,
    }: {
      contractAddr: address;
      tokenId: address;
    }) => {
      await fetchNFTMetadata({
        dispatch,
        chainID,
        contractAddr,
        tokenId,
      });
    },
    [chainID, dispatch]
  );

  return { preloadNftMetadata, preloadWalletNfts, refreshNftMetadata };
}
