import useAxios from "axios-hooks";
import { Trade } from "../interfaces";

export function useUpdateTradeAssets({
  tradeId,
}: {
  // participantToken: string | null;
  tradeId: string | null;
}) {
  const url = `${process.env.REACT_APP_API_HOST}/trades/${tradeId}/assets`;
  const [{ data, loading, error: err }, updateTradeAssets, cancelRequest] =
    useAxios<Trade>(
      {
        url,
        method: "PUT",
        withCredentials: true,
      },
      { manual: true }
    );

  return { data, err, loading, updateTradeAssets, cancelRequest };
}
