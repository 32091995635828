import styled from "styled-components";

interface Props {
  marginRight?: number;
}

export const Column = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 ${(props) => props.marginRight ?? "0"}px 0 0;
`;
