import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import React from "react";

// interface JoinTradeButtonProps {}

export default function JoinTradeButton() {
  const [focus, setFocus] = React.useState(false);
  const handleClick = () => {
    console.log("handle join trade");
  };

  const handleFocus = () => setFocus(!focus);
  const handleBlur = () => setFocus(!focus);

  return (
    <InputGroup size='lg' w='320px' h='55px'>
      <Input
        type='text'
        placeholder='Enter trade code'
        focusBorderColor='#333'
        borderRadius='66px'
        bg='rgba(242, 242, 242, 0.6)'
        borderWidth='0px'
        _placeholder={{ color: "#BDBDBD", fontSize: "16px" }}
        _focus={{
          borderWidth: "2px",
          borderColor: "#333",
          color: "#333",
          boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.12)",
        }}
        _hover={{
          borderWidth: "2px",
          borderColor: "#333",
          color: "#333",
          boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.12)",
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <InputRightElement width='4.5rem'>
        <Button
          h='43px'
          w='64px'
          bg={focus === true ? "#333" : "#E0E0E0"}
          fontSize='16px'
          color='#fff'
          borderRadius='36px'
          _active={{ bg: "#333" }}
          _focus={{ border: "0" }}
          size='sm'
          onClick={handleClick}
        >
          Join
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}
