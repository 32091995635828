import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { AlchemyNftMetadata } from "../hooks/AlchemyNftMetadata";
import { closeNftModal, useAppContext } from "../hooks/useAppContext";
import { usePreloadNftMetadata } from "../hooks/usePreloadNftMetadata";
import { Asset } from "../interfaces";
import { Heading1 } from "./Heading.react";
import { NftModalBar } from "./NftModalBar.react";
import { NftOption } from "./NftOption.react";
import { NftsModalScrollableContent } from "./NftsModalScrollableContent.react";
import { Row } from "./Row.react";

const Input = styled.input`
  background: #ffffff;
  border: 2px solid #e0e0e0;
  color: #333;
  /* set to 6 since due to line height of heading + heading layout this gives us 16px space like the designs */
  margin-top: 6px;
  padding: 0 16px;
  border-radius: 16px;
  font-family: Raleway;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  width: 314px;
  height: 51px;
  outline: none;
  overflow: hidden;
  transition: filter 150ms;
  cursor: pointer;

  &:focus,
  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
`;

export function SelectNftsModal(): React.ReactElement {
  const { state, dispatch } = useAppContext();
  const [query, setQuery] = useState<string>("");
  const selectedAssets: Set<string> = new Set<string>(
    state.selectedNfts.map(
      (asset: Asset) => `${asset.tokenAddress}:${asset.tokenId}`
    )
  );
  const walletID = state.nftModalWalletID;
  const walletNFTs = state.walletAddrToNfts.get(walletID || "") || [];
  const { preloadNftMetadata } = usePreloadNftMetadata({
    ownerWalletAddr: walletID!,
  });

  useEffect(() => {
    // load nfts for counterparty
    if (
      state.nftModalWalletID != null &&
      state.walletID !== state.nftModalWalletID
    ) {
      preloadNftMetadata();
    }
  }, [state.nftModalWalletID, state.walletID, preloadNftMetadata]);

  return (
    <Modal
      isOpen={walletID != null}
      contentLabel={
        state.walletID !== state.nftModalWalletID ? "Their NFTs" : "Select NFTs"
      }
      closeTimeoutMS={500}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      shouldFocusAfterRender
      onAfterOpen={() => setQuery("")}
      onRequestClose={() => dispatch(closeNftModal())}
      style={{
        content: {
          position: "unset",
          display: "flex",
          margin: "30px auto 0px auto",
          flexDirection: "column",
          alignSelf: "center",
          alignItems: "flex-start",
          padding: "26px 12px 0px 26px",
          minHeight: "400px",
          maxWidth: "852px",
          maxHeight: "810px",
          boxShadow: " 0px 8px 24px rgba(0, 0, 0, 0.12)",
          borderRadius: "24px",
          overflow: "hidden",
          transition: "height 500ms ease-in-out",
        },
        overlay: {
          opacity: walletID != null ? "1" : "0",
          transition: "opacity 500ms ease-in-out",
          backgroundColor: "rgba(0, 0, 0, .8)",
        },
      }}
    >
      <Heading1 xlarge>
        {state.walletID !== state.nftModalWalletID
          ? "Their NFTs"
          : "Select NFTs"}
      </Heading1>
      <Row>
        <Input
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setQuery(e.currentTarget.value.trim())
          }
          placeholder='Search by name or token id'
        />
      </Row>
      <Row>
        <NftsModalScrollableContent>
          {walletNFTs?.map((nft: AlchemyNftMetadata.RootObject) => {
            return (
              <NftOption
                selectable={state.walletID === state.nftModalWalletID}
                contractAddr={nft.contract.address}
                tokenId={nft.id.tokenId}
                filter={query}
                preSelected={selectedAssets.has(
                  `${nft.contract.address}:${nft.id.tokenId}`
                )}
                key={`${nft.contract.address}:${nft.id.tokenId}`}
              ></NftOption>
            );
          })}
        </NftsModalScrollableContent>
      </Row>
      <Row>
        <NftModalBar />
      </Row>
    </Modal>
  );
}
