import styled from "styled-components";

interface Props {
  // https://developer.mozilla.org/en-US/docs/Web/CSS/flex-grow
  readonly grow?: number;
}

export const CardInfoText = styled.span<Props>`
  width: 261px;
  height: 19px;
  margin: 0 0 8px 0;

  font-family: "Raleway";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  text-align: center;

  color: #bdbdbd;
`;
