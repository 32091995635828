import React, { useEffect, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import styled, { css } from "styled-components";
import {
  selectAssetFromCache,
  selectNft,
  unselectNft,
  useAppContext,
} from "../hooks/useAppContext";
import { Asset } from "../interfaces";
import { Column } from "./Column.react";
import { Row } from "./Row.react";

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  color: #333333;
  flex-grow: 2;
  width: 180px;
  padding: 10px;
  background: #ffffff;
  height: 55px;
  text-align: left;
  font-size: 14px;
`;

interface ItemDetailTextProps {
  readonly primary?: boolean;
}

const ItemDetailText = styled.div<ItemDetailTextProps>`
  width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #cccccc;
  ${(props) =>
    props.primary &&
    css`
      line-height: 18px;
      font-size: 12px;
      font-weight: bold;
      color: #333333;
    `}
  ${(props) =>
    !props.primary &&
    css`
      line-height: 14px;
      font-size: 9px;
      font-weight: normal;
    `}
`;

const IconContainer = styled.div`
  width: 180px;
  height: 180px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 16px;
  overflow: hidden;
`;

const Img = styled.img`
  user-drag: none;
  width: 100%;
`;

interface RadioButtonProps {
  readonly hover?: boolean;
  readonly selected?: boolean;
}
const RadioButton = styled.div<RadioButtonProps>`
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 0px;

  width: 24px;
  height: 24px;

  flex: none;
  order: 1;
  flex-grow: 0;

  font-family: "Raleway";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  background-color: rgb(130, 130, 130);
  border-radius: 50%;
  border: none;
  color: #ffffff;
  cursor: pointer;

  ${(props) =>
    props.selected &&
    css`
      background-color: #333333;
      border-width: 1px;
    `}
  ${(props) =>
    !props.selected &&
    props.hover &&
    css`
      border: 2px solid #ffffff;
      outline: 1px solid #333333;
    `}
  &:hover,
  &:focus {
    background-color: #333333;
  }
`;

interface OptionContainerProps {
  readonly hover?: boolean;
  readonly selected?: boolean;
}
const OptionContainer = styled.button<OptionContainerProps>`
  position: relative;
  width: 180px;
  height: 235px;
  outline: 0px solid transparent;
  border: none;
  background: #c4c4c4;
  padding: 0px;
  transform: scale(1);
  transition: all 200ms ease-in-out;
  ${(props) =>
    props.selected &&
    css`
      outline: 4px solid #333333;
    `}
  ${(props) =>
    !props.selected &&
    props.hover &&
    css`
      transform: scale(1.02);
      transition: all 200ms ease-in-out;
    `}
  border-radius: 16px;
  overflow: hidden;
  margin: 0px 12px 24px 0;
  box-shadow: -2px 6px 17px -15px #000000;
  cursor: pointer;
`;

interface Props {
  contractAddr: string;
  tokenId: string;
  filter: string;
  preSelected: boolean;
  selectable: boolean;
}

export function NftOption({
  contractAddr,
  tokenId,
  filter,
  preSelected,
  selectable,
}: Props): React.ReactElement {
  const { dispatch, state } = useAppContext();
  const [selected, setSelected] = useState<boolean>(preSelected);
  const [hover, setHover] = useState<boolean>(false);
  const [hoverEnabled, setHoverEnabled] = useState<boolean>(true);
  const [match, setMatch] = useState<boolean>(false);
  const [asset, setAsset] = useState<Asset>();
  const assetCacheKey = `${contractAddr}:${tokenId}`;
  const cachedAsset = selectAssetFromCache(state, assetCacheKey);

  useEffect(() => {
    if (cachedAsset != null) {
      setAsset(cachedAsset);
      setMatch(
        !!cachedAsset.type && cachedAsset.name.toLowerCase().search(filter) >= 0
      );
      return;
    }
  }, [cachedAsset, filter, assetCacheKey]);

  return !match || !asset ? (
    <div style={{ display: "none" }}></div>
  ) : (
    <Column>
      <OptionContainer
        selected={selected}
        hover={hover}
        onClick={() => {
          if (!selectable) return;

          setHoverEnabled(false);
          setSelected(!selected);

          if (!selected) {
            dispatch(selectNft(asset));
          } else {
            dispatch(unselectNft(asset));
          }
        }}
        onMouseOver={() => {
          hoverEnabled && setHover(true);
          setHoverEnabled(false);
        }}
        onMouseOut={() => {
          setHover(false);
          !selected && setHoverEnabled(true);
        }}
      >
        <IconContainer>
          {asset.image && (
            <Img
              alt={asset.tokenAddress + " " + asset.tokenId}
              src={asset.image}
            />
          )}
        </IconContainer>
        <Row>
          <ItemDetails>
            <ItemDetailText primary>{asset.name}</ItemDetailText>
            <ItemDetailText>{contractAddr}</ItemDetailText>
          </ItemDetails>
        </Row>
        {selectable && (
          <RadioButton
            hover={hover}
            selected={selected}
            aria-label='Remove NFT from trade'
          >
            {selected && <BsCheckLg size={8} />}
          </RadioButton>
        )}
      </OptionContainer>
    </Column>
  );
}
