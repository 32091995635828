import styled from "styled-components";

export const Body = styled.div`
  font-family: "Raleway";
  align-items: center;

  background: #fff;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  margin-top: 120px;
`;
