import useAxios from "axios-hooks";
import { Trade } from "../interfaces";

export function useLockTrade({
  participantToken,
  tradeId,
}: {
  participantToken: string | null;
  tradeId: string | null;
}) {
  const url = `${process.env.REACT_APP_API_HOST}/trades/${tradeId}/lock`;
  const [{ data, loading, error: err }, lockTrade] = useAxios<Trade>(
    { url, method: "POST", withCredentials: true, data: { participantToken } },
    { manual: true }
  );

  return { data, err, loading, lockTrade };
}
