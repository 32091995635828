import React from "react";
import styled, { css } from "styled-components";
import { unselectNft, useAppContext } from "../hooks/useAppContext";
import { useUpdateTradeAssets } from "../hooks/useUpdateTradeAssets";
import { Asset } from "../interfaces";
import { Row } from "./Row.react";

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  color: #333333;
  margin: 8px 0;
  flex-grow: 2;
`;

interface ItemDetailTextProps {
  readonly primary?: boolean;
}

const ItemDetailText = styled.div<ItemDetailTextProps>`
  ${(props) =>
    props.primary &&
    css`
      line-height: 18px;
      font-size: 14px;
      font-weight: bold;
    `}
  ${(props) =>
    !props.primary &&
    css`
      line-height: 14px;
      font-size: 9px;
      font-weight: normal;
    `}
`;

const IconContainer = styled.div`
  width: 72px;
  height: 72px;

  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 16px;
  overflow: hidden;
`;

const RemoveButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 0px 0px 2px 0px;

  width: 24px;
  height: 24px;

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 12px;

  font-family: "Raleway";
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  background-color: rgb(130, 130, 130);
  border-radius: 50%;
  border-width: 0;
  color: #ffffff;
  cursor: pointer;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover,
      &:focus {
        background-color: #333333;
      }
    `}

  &:disabled {
    cursor: default;
  }
`;

const Img = styled.img`
  user-drag: none;
  width: 100%;
  height: 100%;
`;

interface Props {
  asset: Asset;
  enabled: boolean;
}

export function SwapItem({ asset, enabled }: Props): React.ReactElement {
  const { dispatch, state } = useAppContext();
  const { updateTradeAssets, loading } = useUpdateTradeAssets({
    tradeId: state.trade.tradeID,
  });

  return (
    <Row
      style={{
        display: loading ? "none" : "flex",
      }}
    >
      <IconContainer>
        {asset.image && <Img alt={asset.tokenId} src={asset.image} />}
      </IconContainer>
      <ItemDetails>
        <ItemDetailText primary>{asset.name}</ItemDetailText>
        <ItemDetailText></ItemDetailText>
      </ItemDetails>
      {enabled && (
        <RemoveButton
          onClick={() => {
            dispatch(unselectNft(asset));
            updateTradeAssets({
              data: {
                // tslint:disable-next-line
                assets: state.selectedNfts.filter((selectAsset) => {
                  const selectedAsset = `${selectAsset.tokenId}:${selectAsset.tokenAddress}`;
                  const target = `${asset.tokenId}:${asset.tokenAddress}`;
                  return selectedAsset != target;
                }),
                // tslint:disable-next-line
                participantToken: state.participantToken,
              },
            });
          }}
          type='button'
          disabled={!enabled || loading}
          aria-label='Remove NFT from trade'
        >
          x
        </RemoveButton>
      )}
    </Row>
  );
}
