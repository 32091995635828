import styled from "styled-components";

interface HeadingBaseProps {
  // https://developer.mozilla.org/en-US/docs/Web/CSS/flex-grow
  readonly grow?: number;
  readonly block?: boolean;
}

const HeadingBase = styled.span<HeadingBaseProps>`
  display: ${(props) => (props.block === true ? "block" : "inline")};
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;

  color: #333333;

  flex-grow: ${(props) => (props.grow && props.grow >= 0 ? props.grow : 0)};
`;

export const Heading1 = styled(HeadingBase)<{ xlarge?: boolean }>`
  font-size: ${(props) => (props.xlarge ? "40px" : "28px")};
  line-height: 46px;
  font-weight: bold;
  font-family: "Raleway";
`;

export const Heading2 = styled(HeadingBase)`
  font-size: 16px;
  line-height: 22px;
  font-family: "Raleway";
  color: #828282;
  font-weight: bold;
  text-align: center;
  white-space: pre-wrap;
`;
