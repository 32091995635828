import { ApolloProvider } from "@apollo/react-hooks";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "@fontsource/raleway/600.css";
import "@fontsource/raleway/700.css";
import "@fontsource/raleway/800.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ApolloClient from "apollo-boost";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AppContextProvider } from "./hooks/useAppContext";
import "./index.css";
import AppRoutes from "./routes/AppRoutes";

Sentry.init({
  dsn: "https://304cee33c6d74d1482ff959c0be53d13@o1153254.ingest.sentry.io/6232211",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const customTheme = extendTheme({
  fonts: {
    heading: "Raleway, sans-serif",
    body: "Raleway, sans-serif",
  },
});

// You should replace this url with your own and put it into a .env file
// See all subgraphs: https://thegraph.com/explorer/
const client = new ApolloClient({
  uri: "https://api.thegraph.com/subgraphs/name/paulrberg/create-eth-app",
});

ReactDOM.render(
  <ChakraProvider theme={customTheme}>
    <ApolloProvider client={client}>
      <AppContextProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </AppContextProvider>
    </ApolloProvider>
  </ChakraProvider>,
  document.getElementById("root")
);
