import { Web3Provider } from "@ethersproject/providers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useCallback, useEffect, useState, useContext } from "react";
import Web3Modal from "web3modal";
import { Web3ModalContext } from "./useAppContext";

// Enter a valid infura key here to avoid being rate limited
// You can get a key for free at https://infura.io/register
const INFURA_ID = process.env.REACT_APP_INFURA_ID;

const Networks = {
  MAINNET: "mainnet",
  RINEKBY: "rinkeby",
};

const NETWORK = Networks.RINEKBY;

function useWeb3Modal(config = {}) {
  const [provider, setProvider] = useState();
  const [autoLoaded, setAutoLoaded] = useState(false);
  const { autoLoad = true, infuraId = INFURA_ID, network = NETWORK } = config;
  const { web3Modal, setWeb3Modal } = useContext(Web3ModalContext);

  // Web3Modal also supports many other wallets.
  // You can see other options at https://github.com/Web3Modal/web3modal
  useEffect(
    () => {
      if (web3Modal != null) {
        return;
      }

      setWeb3Modal(
        new Web3Modal({
          network,
          cacheProvider: true,
          disableInjectedProvider: false,
          providerOptions: {
            walletconnect: {
              package: WalletConnectProvider,
              options: {
                infuraId,
              },
            },
          },
        })
      );
    },
    [infuraId, network, setWeb3Modal, web3Modal]
  );

  // TODO subscribe to events from wallet (discount, chainChanged, etc)
  // https://github.com/Web3Modal/web3modal#provider-events

  // Open wallet selection modal.
  const loadWeb3Modal = useCallback(
    async () => {
      try {
        const newProvider = await web3Modal.connect();
        setProvider(new Web3Provider(newProvider));
      } catch (e) {
        console.error(
          "[Swap.new Team] Failed to get accounts, open metamask if it is locked or try updating it",
          e
        );
      }
    },
    [web3Modal]
  );

  const logoutOfWeb3Modal = useCallback(
    async () => {
      await web3Modal.clearCachedProvider();
      window.location.reload();
    },
    [web3Modal]
  );

  // If autoLoad is enabled and the the wallet had been loaded before, load it automatically now.
  useEffect(
    () => {
      if (autoLoad && !autoLoaded && web3Modal?.cachedProvider) {
        loadWeb3Modal();
        setAutoLoaded(true);
      }
    },
    [
      autoLoad,
      autoLoaded,
      loadWeb3Modal,
      setAutoLoaded,
      web3Modal?.cachedProvider,
    ]
  );

  return { provider, loadWeb3Modal, logoutOfWeb3Modal };
}

export default useWeb3Modal;
