import { Web3Provider } from "@ethersproject/providers";
import { NftSwapV3 } from "@traderxyz/nft-swap-sdk";
import { useEffect } from "react";
import Modal from "react-modal";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import {
  addWalletName,
  setSelectedNfts,
  TxHashStatus,
  updateParticipantToken,
  updateTrade,
  updateTxHashStatus,
  useAppContext,
} from "../hooks/useAppContext";
import { useCreateOrJoinTrade } from "../hooks/useCreateOrJoinTrade";
import { useNftSwapSdk } from "../hooks/useNftSwapSdk";
import useWeb3Modal from "../hooks/useWeb3Modal";
import { Participant } from "../interfaces";

Modal.setAppElement("#root");
const overlay = Modal?.defaultStyles?.overlay || {};
overlay.backgroundColor = "rgba(0, 0, 0, .8)";

function App() {
  const { provider } = useWeb3Modal();
  const { state, dispatch } = useAppContext();
  const { tradeId } = useParams();
  const navigate = useNavigate();

  const { createOrJoinTrade, data: tradeData } = useCreateOrJoinTrade({
    walletID: state.walletID,
    tradeID: tradeId || "",
  });
  const { nftSwapSdk }: { nftSwapSdk?: NftSwapV3 } = useNftSwapSdk();

  useEffect(() => {
    async function getTxStatus(txHash: string) {
      if (!nftSwapSdk) {
        return;
      }
      dispatch(updateTxHashStatus(TxHashStatus.PENDING));
      const fillTxReceipt = await nftSwapSdk.awaitTransactionHash(txHash);

      if (fillTxReceipt.status == 0) {
        dispatch(updateTxHashStatus(TxHashStatus.FAILED));
      } else if (fillTxReceipt.status == 1) {
        dispatch(updateTxHashStatus(TxHashStatus.CONFIRMED));
      } else {
        throw new Error("invalid transaction status");
      }
    }
    if (!!state.trade.txHash && nftSwapSdk) {
      getTxStatus(state.trade.txHash);
    }
  }, [state.trade.txHash, nftSwapSdk, dispatch]);

  useEffect(() => {
    if (tradeData == null) {
      return;
    }

    dispatch(updateParticipantToken(tradeData.participantToken));
    dispatch(updateTrade(tradeData));

    navigate("/" + tradeData.tradeID);
  }, [dispatch, tradeData, tradeData?.tradeID, navigate]);

  useEffect(() => {
    // create trade after connecting the wallet
    if (state.walletID != null && state.trade.tradeID == null) {
      createOrJoinTrade();
    }

    // reload selected assets
    state.trade.participants.forEach(async (participant: Participant) => {
      if (participant.walletID == state.walletID) {
        dispatch(setSelectedNfts(participant.assets));
        return;
      }
      const walletID = participant.walletID;
      const _provider = provider as unknown as Web3Provider;

      if (!_provider) {
        return;
      }
      let name = await _provider.lookupAddress(walletID);
      // Render either the ENS name or the shortened account address.
      if (!name) {
        name = walletID.substring(0, 6) + "..." + walletID.substring(36);
      }
      dispatch(addWalletName(walletID, name));
    });
  }, [
    createOrJoinTrade,
    dispatch,
    provider,
    state.trade,
    state.trade.tradeID,
    state.walletID,
  ]);

  return (
    <>
      <div id='App'>
        <Outlet />
      </div>
    </>
  );
}

export default App;
