import { Web3Provider } from "@ethersproject/providers";
import React, { useEffect } from "react";
import {
  addWalletName,
  updateWalletID,
  useAppContext,
} from "../hooks/useAppContext";
import { Button } from "./Button.react";

interface WalletProps {
  provider?: Web3Provider;
  loadWeb3Modal: () => Promise<void>;
  logoutOfWeb3Modal: () => Promise<void>;
}

export function WalletButton({
  provider,
  loadWeb3Modal,
  logoutOfWeb3Modal,
}: WalletProps) {
  const { state, dispatch } = useAppContext();

  useEffect(() => {
    async function fetchAccount() {
      try {
        if (!provider) {
          return;
        }

        // Load the user's accounts.
        const accounts = await provider.listAccounts();
        const address: string = accounts[0].toLowerCase();
        // Resolve the ENS name for the first account.
        let name = await provider.lookupAddress(accounts[0]);

        // Render either the ENS name or the shortened account address.
        if (!name) {
          name = address.substring(0, 6) + "..." + address.substring(36);
        }

        dispatch(updateWalletID(address));
        dispatch(addWalletName(address, name));
      } catch (err) {
        dispatch(updateWalletID(""));
        console.error(err);
      }
    }
    fetchAccount();
  }, [provider, dispatch]);
  const walletID = state.walletID ?? "";

  return (
    <Button
      dark
      title={state.walletNames.get(walletID)}
      width={125}
      height={40}
      onClick={() => {
        if (!provider) {
          loadWeb3Modal();
        } else {
          logoutOfWeb3Modal();
        }
      }}
    >
      {!state.walletNames.get(walletID)
        ? "Connect Wallet"
        : state.walletNames.get(walletID)}
    </Button>
  );
}
