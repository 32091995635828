import { useCallback } from "react";
import { AppStateInterface, useAppContext } from "./useAppContext";
import useWeb3Modal from "./useWeb3Modal";

// eslint-disable-next-line @typescript-eslint/ban-types
export function useRequireState<T extends Function>(
  fn: T,
  stateValidator: (state: AppStateInterface) => boolean
) {
  const { loadWeb3Modal } = useWeb3Modal();
  const { state } = useAppContext();

  return useCallback(
    async (...args) => {
      if (stateValidator(state)) {
        return fn(...args);
      } else {
        await loadWeb3Modal();

        return fn(...args);
      }
    },
    [stateValidator, state, fn, loadWeb3Modal]
  );
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function useRequireWallet<T extends Function>(fn: T) {
  return useRequireState(
    fn,
    (state) => Boolean(state.walletID) && state.walletID != null
  );
}
