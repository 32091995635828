import useAxios from "axios-hooks";
import { address, CreateOrJoinTradeResponse } from "../interfaces";

export function useCreateOrJoinTrade({
  walletID,
  tradeID,
}: {
  walletID: address | null;
  tradeID: string | null;
}) {
  let url;
  let reqData;

  if (!tradeID) {
    url = `${process.env.REACT_APP_API_HOST}/trades`;
    reqData = { walletID };
  } else {
    url = `${process.env.REACT_APP_API_HOST}/trades/${tradeID}/join`;
    reqData = { walletID, tradeID };
  }
  const [{ data, loading, error: err }, createOrJoinTrade] =
    useAxios<CreateOrJoinTradeResponse>(
      { url, method: "POST", data: reqData, withCredentials: true },
      { manual: true }
    );
  return { data, err, loading, createOrJoinTrade };
}
