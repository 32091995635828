import { ReactNode } from "react";
import styled, { css } from "styled-components";

interface RootProps {
  readonly info?: boolean;
  readonly primary?: boolean;
  readonly pretty?: boolean;
  readonly status?: string;
}

interface ComponentProps {
  readonly children: ReactNode;
  readonly onClick?: () => void;
}

interface StatusProps {
  readonly color: string;
}

type Props = RootProps & ComponentProps;

const Root = styled.div<RootProps>`
  border: 2px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 36px;

  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  height: 29px;
  padding: 8px 14px;
  display: flex;
  align-items: center;
  align-self: center;
  line-height: 24px;

  ${(props) =>
    props.info &&
    css`
      background: #000;
      color: #fff;
      border: 0 none;
    `}

  ${(props) =>
    props.primary &&
    css`
      background: #ffffff;
      color: #333333;
    `}

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  ${(props) =>
    props.pretty &&
    css`
      transition: filter 100ms;
      background: linear-gradient(263.42deg, #00ffd8 -22.8%, #1857f2 106.87%);

      &:hover,
      &:focus {
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.25));
        cursor: pointer;
        border: none;
      }
    `}
`;

const colorMap: { [prop: string]: string } = {
  green: "#27AE60",
  red: "#ff0000",
};

const Status = styled.span<StatusProps>`
  width: 10px;
  height: 10px;
  background: ${(props) =>
    colorMap[props.color] ? colorMap[props.color] : "#000"};
  border-radius: 50%;
  margin-right: 10px;
`;

export function Pill({ children, status, ...props }: Props) {
  return (
    <Root {...props}>
      {status != null && <Status color={status} />}
      {children}
    </Root>
  );
}
